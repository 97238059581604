import React from 'react';
import {Footer, HeaderNav, Pricing} from '../layouts/potency/index';
import StripeFooter from '../layouts/potency/StripeFooter';

const PricingPage = () => {
    return (
        <div className="body-copyright-light fixed counter-scroll has-one-page home3">              
            <div id="mobile-menu-overlay"><span className="tf-close" /></div>
            <HeaderNav />
            <div id="content" className="content">
                <div className="homepage-personal">
                    <Pricing />
                    <StripeFooter />
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default PricingPage;
