import React, { useState, useRef } from 'react';
import { collection, addDoc } from "firebase/firestore"; 
import { PickerOverlay } from 'filestack-react';
import { useForm } from 'react-hook-form';
//import { Editor } from '@tinymce/tinymce-react';
import { db } from '../../../helpers/firebase';
import { useNavigate } from 'react-router-dom';
import { PlusCircleIcon, CheckIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-hot-toast';
import { accountComplete } from '../../../helpers/utils';
import AccountIncomplete from './account/AccountIncomplete';

const Submission = (props) => {    
    const [pickerShowing, setPickerShowing] = useState(false);
    const [document, setSubmissionDocument] = useState("");
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const toastId = useRef();
    const account_complete = accountComplete(props.user);

    const setDocument = (document) => {
        setPickerShowing(false);
        setSubmissionDocument(document);
        setValue('document', document, { shouldValidate: true });
    }

    const onSubmit = async(data) => {
        toastId.current = toast.loading(
            `Submitting...`
        );
        setLoading(true);
        data.status = "draft";
        setLoading(false);
        
        await addDoc(collection(db, "/users/"+props.user.uid+"/submissions"), data);
        setLoading(false);
        toast.success(`Submission Added`, {
            id: toastId.current,
        });
        history.push("/account");      
    }

    return (
        <>
            {!account_complete?<AccountIncomplete />:
            <form onSubmit={handleSubmit(onSubmit)}>
            { pickerShowing ?
                <PickerOverlay
                    apikey="AxUeFMAWsRB6DEOILxedKz"
                    pickerOptions={{
                                    fromSources: ["local_file_system"],
                                    accept: ['.doc', '.docx'],
                                    onClose: () => { setPickerShowing(false) }                                                             
                                    }} 
                    onSuccess={(res) => setDocument(res.filesUploaded[0])}
                /> : null } 
                <div className="row position-relative">
                    <div className="col-12 flat-title">
                        <h3 className="color-d12 mg-b41"><PlusCircleIcon className='hero-icon-title' /> New Submission</h3>
                    </div>
                    <div className="col-12 mb-3">                                                                                            
                        <input type="text" placeholder="Title" {...register("title", {required: true})} />
                        {errors.title?.type === 'required' && <span className="form-error">Title is required</span>}
                        <select className="mt-3" {...register("category", { required: true })}>
                            <option value="">- Select Category -</option>
                            <option value="wellness">Wellness</option>
                            <option value="adventure">Adventure</option>
                            <option value="culture">Culture</option>
                            <option value="innovation">Innovation</option>
                        </select>
                        {errors.category?.type === 'required' && <span className="form-error">Category is required</span>}
                    </div>
                    <div className="col-6">
                        <input type="text" className="d-none" value={document} {...register("document", { required: true })} />   
                        <div className="hire-me-s1 btn-general btn-hv-common socials-list-hd">
                            <button onClick={() => setPickerShowing(true)} className="btn-lg btn-inner border-corner2 lt-sp08" disabled={pickerShowing}>
                                Upload Document
                            </button>
                        </div>                                                                                       
                        {/*<Editor apiKey='7mfvj362ell4d0y33ssaj78r17fczc1lrfg9skcqpdzvpuuj' initialValue="<p>Start typing your magic here.</p>" init={{height: 500}} onChange={handleEditorChange} />*/}
                    </div>
                    <div className="col-6">
                        <div className="hire-me-s1 btn-general btn-hv-common socials-list-hd">
                            <button type="submit" className="btn-lg btn-inner border-corner2 lt-sp08 pull-right" disabled={loading}>{loading?<i className='fa fa-spinner fa-spin'></i>:"Create Draft"}</button>
                        </div>
                    </div>
                    <div className="col-12">
                        {errors.document?.type === 'required' && <span className="form-error">Click to upload your submission document (.doc, .docx)</span>}
                    </div>
                    <div className="col-12">
                        {document.filename?<small><CheckIcon className='hero-icon' /> Uploaded: {document.filename}</small>:null}
                    </div>
                </div>
            </form>}
        </>  
    );
}

export default Submission;
