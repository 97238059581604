import React, { useState, useEffect } from 'react';
import { PlusCircleIcon, ArrowLeftOnRectangleIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import Upgrade from './upgrade';
import Subscribed from './subscribed';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import {Link} from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { db } from '../../../../helpers/firebase';
import { collection, query, where, getDocs } from "firebase/firestore";

const Sidebar = (props) => { 
    const user = props.user;

    const [ subscription, setSubscription ] = useState(null);

    useEffect(() => {
        const getSub = async(uid) => {
            const q = query(collection(db, "users/"+uid+"/subscriptions"), where("status", "in", ['active', 'trialing']));
            const querySnapshot = await getDocs(q);
            if(querySnapshot.size > 0) {
                setSubscription(true);
            }
            else {
                setSubscription(false);
            }
        }

        if(user !== null && user !== undefined) {
            getSub(user.uid);
        }
    }, [user]);

    return (
        <div className="col-12 col-md-4 flat-title account-side-bar">
            <h3 className="color-d12 mb-3">Hey {user.first_name} 👋</h3>
            {user.account_complete?
            <p className="mb-1"><Link to="/submission"><PlusCircleIcon className='hero-icon' /> Submit Content</Link></p>:null}
            {subscription?<Subscribed />:((subscription !== null)?<Upgrade user={user} />:<Skeleton className="mg-b41" height={38.39} />)}
            <p className="mt-1"><Link to="/settings"><Cog6ToothIcon className='hero-icon' /> Account Settings</Link></p>
            <p className='mb-4'><Link to="/" onClick={() => signOut(getAuth())}><ArrowLeftOnRectangleIcon className='hero-icon' /> Logout</Link></p>
        </div>
    );
}

export default Sidebar;