import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { collection, query, getDocs } from "firebase/firestore";
import { db } from '../../../helpers/firebase';
import { DocumentArrowDownIcon, FolderPlusIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import {Link} from 'react-router-dom';

const Submissions = (props) => {  
    const [submissions, setSubmissions] = useState(null);
    const user = props.user;

    useEffect(() => {
        const getSubmissions = async(uid) => {
            const q = query(collection(db, "users/"+uid+"/submissions"));
            let subs = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                let doc_data = doc.data();
                doc_data.id = doc.id;
                subs.push(doc_data);
            });            
            setSubmissions(subs)
        }

        if(user !== null && user !== undefined) {
            getSubmissions(user.uid);
        }
    }, [user]);

    let subList = null;
    if(submissions?.length > 0) {
        subList = submissions.map(sub => {
            return (
                <div className="row" key={sub.id}>
                    <div className="col-12 mb-3">
                        <div className="card flex-row">
                            <div className="card-body">
                                <h4 className="card-title h5 h4-sm">{sub.title}</h4>
                                <p className="card-text">Status: <span className="badge badge-primary">{sub.status}</span></p>
                                <p className="card-text">Document: {sub.document.filename} <a href={sub.document.url}><DocumentArrowDownIcon className='hero-icon-title' /></a></p>
                            </div>
                        </div>
                    </div>  
                </div>   
            )     
        });
    }
    else {
        subList = (
            <div className='text-center mt-5'>
                <FolderPlusIcon className='hero-icon-large text-muted' />
                <p>No Submissions</p>
                <p className='text-muted'>Get started by making a new submission.</p>
                <div className="hire-me-s1 socials-list-hd mt-4">
                    {user.account_complete?
                    <Link to="/submission" className="btn-sm btn-inner border-corner2 lt-sp08"><PlusCircleIcon className='hero-icon' /> Submit Content</Link>
                    :null}
                </div>
            </div>
        )
    }
    

    return (
        <>
            
            {submissions?(submissions.length > 0?<><h3 className="color-d12 mb-3">Your Submissions</h3> {subList}</>:subList):<Skeleton count={3} height={60} />}
        </>    
    );
}

export default Submissions;
