import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import {Link} from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useAuthState } from '../../../helpers/firebase';
import { Navigate } from 'react-router';

const Login = () => {    
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();        
    const { isAuthenticated } = useAuthState();  
    
    const onSubmit = async(data) => {        
        setError(false)
        setLoading(true)
        const { email, password } = data;
        const auth = getAuth()
        try {
            await signInWithEmailAndPassword(auth, email, password);   
        } catch (e) {
            setError(true)
            setLoading(false)
        }
    }

    if(isAuthenticated) {
        return (<Navigate to='/account' />)
    }
    else {
        return (
            <div className="tf-modal-content">
                <section className="about-me t2 s3 shape-am position-relative" id="about">                 
                    <div className="container mt-5">
                        <div className="row position-relative align-items-center">
                            <div className="col-md-6">
                                <div className="flat-title t2">
                                    <h2 className="title-section color-d12 mg-b41">Login to Potency Collab</h2>
                                    <div className="description">
                                        <p className="lt-sp01">
                                            Don't have an Account? <strong><Link to="/join">Click here</Link></strong> to Join the Team!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                { error ?
                                <div className="alert alert-danger" role="alert">
                                    Invalid Email and/or Password.
                                </div> : "" }
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <input type="text" className="mt-3" placeholder="Email" {...register("email", {required: true, pattern: /^\S+@\S+$/i})} />
                                    {errors.email?.type === 'required' && <span className="form-error">Email Address is required</span>}
                                    {errors.email?.type === 'pattern' && <span className="form-error">Email Address looks invalid</span>}
                                    <input type="password" className="mt-3" placeholder="Password" {...register("password", {required: true})} />
                                    {errors.password?.type === 'required' && <span className="form-error">Password is required</span>}
                                    <br /><button className="mt-4 btn-lg btn-inner border-corner2 lt-sp08" type="submit" disabled={loading}>{loading?<i className='fa fa-spinner fa-spin'></i>:"Login"}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>    
        );
    }
}

export default Login;
