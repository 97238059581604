import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css'
import BlockText from './BlockText';

const Vision = () => {
    return (
        <section className="about-me s3 position-relative" id="about">                
            <div className="container">
                <div className="row position-relative">
                    <div className="col-12 block-text">
                        <img src="https://balance.b-cdn.net/potency/our_vision_banner.png" alt="Our Vision" />                                                                     
                    </div>
                    <div className="col-12 my-1 my-md-3"></div>
                    <div className="col-12 block-text">
                        <BlockText pageID="1hUnK5FDhd6gLyIRvIaiD9" skelHeight={600} /> { /* About Page content */ }                                                                       
                    </div>
                    <div className="col-12 my-5"></div>
                    <div className="col-12 col-md-9 block-text d-flex align-items-center">
                        <div>
                            <BlockText pageID="23aWN906Jh8jxrcQxDp6QN" skelHeight={300} /> { /* Justine content */ }
                        </div>
                    </div>
                    <div className="col-12 col-md-3 d-flex align-items-center mt-3 mt-md-0">
                        <img src="https://balance.b-cdn.net/potency/Justine-2.png" className="img-fluid rounded" alt="Potency Collab - Justine" />
                    </div>
                    <div className="col-12 my-5"></div>
                    <div className="col-12 col-md-9 block-text d-flex align-items-center">
                        <div>
                            <BlockText pageID="29PeFLJAXHB55Oe4ObHrzv" skelHeight={300} /> { /* Franko content */ }
                        </div>
                    </div>
                    <div className="col-12 col-md-3 d-flex align-items-center mt-3 mt-md-0">
                        <img src="https://balance.b-cdn.net/potency/Franko-2.png" className="img-fluid rounded" alt="Potency Collab - Franko" />
                    </div>
                    <div className="col-12 my-5"></div>
                    <div className='col-12 mt-5'>                            
                        <h1>Our Upcoming Events </h1>
                        <p>Please enquire to be a host or guest speaker for any of the following Potency Events.</p>
                        <p>Contact - Justine Jamieson</p>
                        <p><a href="mailto:justine@potencycreative.com">justine@potencycreative.com</a></p>
                    </div> 
                </div>
            </div>
        </section>  
    );
}

export default Vision;
