import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/potency-logo.jpg';
import { useAuthState } from '../../../helpers/firebase';

const HeaderNav = () => {
    const { isAuthenticated } = useAuthState();
    return (
        <header id="header" className="header header-style3 has-menu-model bg-white">
            <div className="menu-container">
                <nav className="navbar navbar-expand-lg navbar-light pt-0">
                    <Link to="/" onClick={() => {window.location.href="/"}} title="Potency"><img src={logo} className="nav-logo" alt="Potency" /></Link>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto topnav">
                            <li className="nav-item">
                                <Link to="/" className="nav-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className="nav-link">Vision</Link>
                            </li>
                            {!isAuthenticated?<li className="nav-item">
                                <Link to="/login" className="nav-link">Login</Link>
                            </li>:null}
                            {isAuthenticated?
                            <li className="nav-item nav-button hire-me-s1 btn-general btn-hv-common">
                                <Link to="/account" className="btn-inner border-corner2 lt-sp08">Your Account</Link>
                            </li>
                            :<li className="nav-item nav-button hire-me-s1 btn-general btn-hv-common">
                                <Link to="/join" className="btn-inner border-corner2 lt-sp08">Join the Team</Link>
                            </li>}
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default HeaderNav;