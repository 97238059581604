import { ExclamationIcon } from "@heroicons/react/outline";
import {Link} from 'react-router-dom';

const AccountIncomplete = () => {
    return (
        <div className="row">
            <div className="col-12 mb-3">
                <div className="alert alert-danger text-center" role="alert">
                    <ExclamationIcon className='hero-icon' /> Please <Link to="/settings"><u>complete your account</u></Link> before making submissions.
                </div>
            </div>
        </div>
    )
}

export default AccountIncomplete;