import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import client from '../../../helpers/contentfulClient';
import BlockText from './BlockText';

const Pricing = () => {
    const [page, setPage] = useState(null);

    useEffect(() => {
        client.getEntry('3SgfeBiCBwMfkOwIqYE1x3') // Pricing Page ID
        .then((entry) => {
            setPage(entry.fields);
            Promise.all([documentToHtmlString(entry.fields.introText), 
                         documentToHtmlString(entry.fields.afterText),
                         documentToHtmlString(entry.fields.freeCard.fields.features),
                         documentToHtmlString(entry.fields.tierTwoCard.fields.features)]).then((values) => {

                document.getElementById('block-pricing').innerHTML = values[0];
                document.getElementById('block-pricing-after').innerHTML = values[1];
                document.getElementById('free-features').innerHTML = values[2];
                document.getElementById('tier-two-features').innerHTML = values[3];
            });
        })
        .catch((error) => console.log(error));
    }, []);

    return (
            <section className="about-me s3 position-relative" id="about">
                <div className="container">
                    <div className="row position-relative align-items-center">
                        <div className="col-12">
                            <div id="block-pricing"><Skeleton height={250} /></div> 
                        </div>
                    </div>
                    <div className="row mt-5 pricing-table">
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="card h-100 shadow-lg">
                                <div className="card-body">
                                    <div className="text-center p-3">
                                        <h5 className="card-title">{!page?<Skeleton height={100} />:page.freeCard.fields.title}</h5>
                                        <span className="h2">{!page?<Skeleton height={100} />:page.freeCard.fields.price}</span>
                                        <br /><br />
                                    </div>
                                </div>
                                <div id="free-features" className='pricing-card'><Skeleton height={450} /></div> 
                                <div className="card-body text-center">
                                    <div className="hire-me-s1 btn-general btn-hv-common socials-list-hd mt-4">
                                        <Link to="/join" className="btn-lg btn-inner border-corner2 lt-sp08">Join now</Link>
                                    </div>                                         
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="card h-100 shadow-lg">
                                <div className="card-body">
                                    <div className="text-center p-3">
                                        <h5 className="card-title">{!page?<Skeleton height={100} />:page.tierTwoCard.fields.title}</h5>
                                        <span className="h2">{!page?<Skeleton height={100} />:page.tierTwoCard.fields.price}</span>{page?page.tierTwoCard.fields.unit:null}
                                        <br /><br />
                                    </div>
                                </div>
                                <div id="tier-two-features" className='pricing-card'><Skeleton height={450} /></div>
                                <div className="card-body text-center">
                                    <div className="hire-me-s1 btn-general btn-hv-common socials-list-hd mt-4">
                                        <Link to="/join" className="btn-lg btn-inner border-corner2 lt-sp08">Join now</Link>
                                    </div>                                         
                                </div>
                            </div>
                        </div>                       
                    </div>
                    <div className="row position-relative align-items-center">
                        <div className="col-12"> 
                            <div id="block-pricing-after"><Skeleton height={250} /></div> 
                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className="col-12 block-text">
                            <BlockText pageID="ow5heE5KrGe3XAA8Wj2M1" /> { /* Content Specifications Block */ }
                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className="col-12 block-text">
                            <BlockText pageID="4POuV5PMgdoTgNX0Mxw8jl" /> { /* Editing Block */ }
                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className="col-12 block-text">
                            <BlockText pageID="6xbj1GXrLVRedw2i9fJUr2" /> { /* Payment Structure Block */ }
                        </div>
                    </div>
                </div>
            </section>
        );
}

export default Pricing;
