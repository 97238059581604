import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './index.css';
import App from './App'
import { Toaster } from 'react-hot-toast';

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/*" element={<App />} />
        </Routes>
        <Toaster />
    </BrowserRouter>
    , document.getElementById('root'));
