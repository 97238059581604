import React from 'react';
import { About, Footer, HeaderNav, ImageCarousel } from '../layouts/potency/index';
import StripeFooter from '../layouts/potency/StripeFooter';
import GradientSection from '../layouts/potency/GradientSection';

const Home = () => {
    const slides = [
        "https://balance.b-cdn.net/potency/slides/slide_2.png", 
        "https://balance.b-cdn.net/potency/slides/slide_3.png", 
        "https://balance.b-cdn.net/potency/slides/slide_4.png", 
        "https://balance.b-cdn.net/potency/slides/slide_1.png", 
        "https://balance.b-cdn.net/potency/slides/slide_5.png",
        "https://balance.b-cdn.net/potency/slides/slide_6.png",
    ]
    return (
        <div className="body-copyright-light fixed counter-scroll has-one-page home3">
            <div id="mobile-menu-overlay"><span className="tf-close" /></div>
            <HeaderNav />
            <div id="content" className="content">
                <div className="homepage-personal">
                    <section style={{paddingTop: '70px'}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 p-0">
                                    <ImageCarousel images={slides} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <About />
                    <GradientSection />
                    <StripeFooter />
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Home;
