import React, {useState, useEffect} from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import client from '../../../helpers/contentfulClient';
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


const renderOptions = {
    renderNode: {
        [INLINES.ASSET_HYPERLINK]: (node) => { 
            return <a target="_blank" rel="noreferrer" href={ node.data && node.data.target.fields.file.url?node.data.target.fields.file.url : null }>{node.data.target.fields.title}</a>
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
            return (<div className="hire-me-s1 btn-general btn-hv-common socials-list-hd mt-3 mb-3">
                        <a className="btn-lg btn-inner border-corner2 lt-sp08" rel="noreferrer" href={node.data.target.fields.link} target="_blank">{node.data.target.fields.title}</a>
                    </div>)
        }
    }
};

const BlockText = (props) => {
    const [page, setPage] = useState(null);

    useEffect(() => {
        client.getEntry(props.pageID).then((entry) => {
            return documentToReactComponents(entry.fields.text, renderOptions);
        }).then((entry) => {
            setPage(entry);
        }).catch((error) => console.log(error));
    }, [props.pageID]);

    return (page?page:<Skeleton height={props.skelHeight?props.skelHeight:250} />);
}

export default BlockText;
