import React, { useState, useRef } from 'react';
import { PickerOverlay } from 'filestack-react';
import { set, useForm } from 'react-hook-form';
import { doc, updateDoc } from "firebase/firestore";
import { db } from '../../../../helpers/firebase';
import { toast } from 'react-hot-toast';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

const Settings = (props) => {
    const [user, setUser] = useState(props.user);
    const { register, setValue, handleSubmit, formState: { errors } } = useForm({ defaultValues: user });
    const [loading, setLoading] = useState(false);
    const [image, setAccountImage] = useState("");
    const [pickerShowing, setPickerShowing] = useState(false);
    const toastId = useRef();

    const onSubmit = async (data) => {
        setLoading(true);
        toastId.current = toast.loading(
            `Updating...`
        );

        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, data);

        toast.success(`Account Updated`, {
            id: toastId.current,
        });

        setLoading(false);
    }

    const setImage = async (image) => {
        console.log(image);
        setPickerShowing(false);
        setAccountImage(image);
        // Update the user image
        setUser({ ...user, image: image  });
        setValue('image', image);

        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, { image: image });

        toast.success(`Photo Updated`, {
            id: toastId.current,
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {pickerShowing &&
                <PickerOverlay
                    apikey="AxUeFMAWsRB6DEOILxedKz"
                    pickerOptions={{
                        fromSources: ["local_file_system"],
                        accept: ["image/*"],
                        transformations: { crop: { aspectRatio: 1 / 1, force: true }, circle: false, rotate: true },
                        onClose: () => { setPickerShowing(false) }
                    }}
                    onUploadDone={(res) => setImage(res.filesUploaded[0].url)}
                />}
            <div className="row position-relative">
                <div className="col-12 flat-title">
                    <h3 className="color-d12 mg-b41"><Cog6ToothIcon className='hero-icon-title' /> Account Settings</h3>
                </div>
                <div className="col-12 mb-3">
                    <div className="offset-3 col-6 offset-md-5 col-md-2 text-center">
                        <input type="text" className="d-none" value={image} {...register("image", { required: true })} />
                        <div 
                            className="c-pointer" 
                            onClick={() => setPickerShowing(true)}
                        >
                            <img 
                                src={user.image ? user.image : "https://via.placeholder.com/200x200"} 
                                className="img-thumbnail" 
                                alt="Account" 
                            />
                        </div>
                    </div>
                    <div className="col-12 text-center mb-3">
                        {!errors.image?.type && <small>Click to {user.image ? "change" : "upload"} photo</small>}
                        {errors.image?.type === 'required' && <span className="form-error">Click to upload photo</span>}
                    </div>

                </div>
            </div>
            <div className='row'>
                <div className="col-12 col-md-6 mb-3">
                    <input type="text" placeholder="First Name" {...register("first_name", { required: true })} />
                    {errors.first_name?.type === 'required' && <span className="form-error">First Name is required</span>}
                    <input className="mt-2" type="text" placeholder="Last Name" {...register("last_name", { required: true })} />
                    {errors.last_name?.type === 'required' && <span className="form-error">Last Name is required</span>}
                    <input className="mt-2" type="text" placeholder="Email" {...register("email", { required: true })} />
                    {errors.email?.type === 'required' && <span className="form-error">Email is required</span>}
                    <input className="mt-2" type="text" placeholder="Phone Number" {...register("phone", { required: true })} />
                    {errors.phone?.type === 'required' && <span className="form-error">Phone Number is required</span>}
                    <select className="mt-2" {...register("country", { required: true })}>
                        <option value="">- Select Country -</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Australia">Australia</option>
                    </select>
                    {errors.country?.type === 'required' && <span className="form-error">Country is required</span>}
                    <select className="mt-2" {...register("expertise", { required: true })}>
                        <option value="">- Select Area of Expertise -</option>
                        <option value="Wellness">Wellness</option>
                        <option value="Adventure">Adventure</option>
                        <option value="Culture">Culture</option>
                        <option value="Innovation">Innovation</option>
                    </select>
                    {errors.expertise?.type === 'required' && <span className="form-error">Area of Expertise is required</span>}
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <input type="text" placeholder="Website" {...register("website")} />
                    <input className="mt-2" type="text" placeholder="Facebook Link" {...register("fb_link")} />
                    <input className="mt-2" type="text" placeholder="Instagram Link" {...register("insta_link")} />
                    <input className="mt-2" type="text" placeholder="Twitter Link" {...register("twitter_link")} />
                    <input className="mt-2" type="text" placeholder="LinkedIn Link" {...register("linked_link")} />
                </div>
            </div>
            <div className="col-12">
                <div className="hire-me-s1 btn-general btn-hv-common socials-list-hd">
                    <button type="submit" className="btn-lg btn-inner border-corner2 lt-sp08 pull-right" disabled={loading}>{loading ? <i className='fa fa-spinner fa-spin'></i> : "Update Settings"}</button>
                </div>
            </div>
        </form>
    );
}

export default Settings;
