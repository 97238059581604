import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuthState } from '../../../helpers/firebase';
import { Navigate } from 'react-router';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from "firebase/firestore"; 
import { db } from '../../../helpers/firebase';

const Join = () => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { isAuthenticated } = useAuthState(); 
    
    const onSubmit = async(data) => {
        setLoading(true)
        setCreating(true)
        const auth = getAuth()
        let promises = [];
        createUserWithEmailAndPassword(auth, data.email, data.password).then(user => {
            delete(data.password)
            promises.push(setDoc(doc(db, "users", user.user.uid), data))
            setCreating(false)
        }).catch(err => {
            setError(err)
        })

        await Promise.all(promises);
        setLoading(false)
    }

    if(isAuthenticated && !creating) {
        return (<Navigate to='/account' />)
    }
    else {
        return (
            <div className="tf-modal-content">
                <section className="about-me t2 s3 shape-am position-relative" id="about">                
                    <div className="container mt-5">
                        <div className="row position-relative align-items-center">
                            <div className="col-md-6">
                                <div className="flat-title t2">
                                    <h2 className="title-section color-d12 mg-b41">Join The Team</h2>
                                    <div className="description">
                                        <p className="lt-sp01">
                                            Already have an Account? <strong><Link to="/login">Click here</Link></strong> to login!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                { error ?
                                <div className="alert alert-danger" role="alert">
                                    Sorry, there was an error. Please try again soon.<br /><small>{error.message}</small>
                                </div> : "" }
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <input type="text" placeholder="First name" {...register("first_name", {required: true, maxLength: 80})} />
                                    {errors.first_name?.type === 'required' && <span className="form-error">First Name is required</span>}
                                    <input type="text" className="mt-3" placeholder="Last name" {...register("last_name", {required: true, maxLength: 100})} />
                                    {errors.last_name?.type === 'required' && <span className="form-error">Last Name is required</span>}
                                    <input type="text" className="mt-3" placeholder="Email" {...register("email", {required: true, pattern: /^\S+@\S+$/i})} />
                                    {errors.email?.type === 'required' && <span className="form-error">Email Address is required</span>}
                                    {errors.email?.type === 'pattern' && <span className="form-error">Email Address looks invalid</span>}
                                    <input type="password" className="mt-3" placeholder="Create Password" {...register("password", {required: true, minLength: 6})} />
                                    {errors.password?.type === 'required' && <span className="form-error">Password is required</span>}
                                    {errors.password?.type === 'minLength' && <span className="form-error">Password must be longer then 6</span>}
                                    <br /><input type="checkbox" className="mt-3" placeholder="Accept Terms" {...register("accept_terms", {required: true})} /> You accept the <Link to="/terms">Terms and Conditions</Link>.
                                    {errors.accept_terms?.type === 'required' && <span className="form-error"><br />Please accept terms and conditions</span>}
                                    <br />
                                    <button className="mt-4 btn-lg btn-inner border-corner2 lt-sp08" type="submit" disabled={loading}>{loading?"Loading":"Join Now"}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>    
        );
    }
}

export default Join;
