import React from 'react';
import logoVert from '../../../assets/images/potency-logo-vertical.png';
import fb_icon from '../../../assets/images/fb-icon.png';
import insta_icon from '../../../assets/images/insta-icon.png';
import twit_icon from '../../../assets/images/twit-icon.png';
import pin_icon from '../../../assets/images/pin-icon.png';

const StripeFooter = () => {
    return (
        <div className="row">
            <div className="col-2 col-xl-2 bg-black text-center pt-3 pb-3" style={{ paddingLeft: '25px' }}>
                <img src={logoVert} alt="Potency Collab" style={{ width: '30px' }} />
            </div>
            <div className="col-2 bg-black">
                &nbsp;
            </div>
            <div className="col-4 bg-black d-flex align-items-center">
                <div className='w-100'>
                    <a href="https://www.facebook.com/potencyedit" target="_blank" rel="noreferrer" className='row p-1 p-md-2 justify-content-center'><img src={fb_icon} alt="Facebook" style={{ width: '30px' }} /></a>
                    <a href="https://www.instagram.com/potencyedit/?hl=en" target="_blank" rel="noreferrer" className='row p-1 p-md-2 justify-content-center'><img src={insta_icon} alt="Instagram" style={{ width: '30px' }} /></a>
                    <a href="#" target="_blank" rel="noreferrer" className='row p-1 p-md-2 justify-content-center'><img src={twit_icon} alt="Twitter" style={{ width: '30px' }} /></a>
                    <a href="#" target="_blank" rel="noreferrer" className='row p-1 p-md-2 justify-content-center'><img src={pin_icon} alt="Pinterest" style={{ width: '30px' }} /></a>
                </div>
            </div>
            <div className="col-4 col-xl-4 bg-black d-flex align-items-center justify-content-center">
                &nbsp;
            </div>
        </div>
    );
}

export default StripeFooter;
