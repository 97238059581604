import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ImageCarousel = (props) => {
    const images = props.images;
    return (
        <Carousel autoPlay={true} showIndicators={false} showStatus={false} infiniteLoop={true} showThumbs={false} stopOnHover={false}>
            {images.map((img, idx) => 
                <div key={"slide_"+idx}>
                    <img alt="" src={img} className='rounded-md' />
                </div>
            )}
        </Carousel>
    );
};

export default ImageCarousel;
