import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import client from '../../../helpers/contentfulClient';


const About = () => {
    const [page, setPage] = useState(null);

    useEffect(() => {
        client.getEntry('1gG3HNvTOC3sbqh10Me8Ic') // Home Page ID
        .then((entry) => {
            setPage(entry.fields);
            Promise.all([documentToHtmlString(entry.fields.blockOne), documentToHtmlString(entry.fields.blockTwo)]).then((values) => {
                document.getElementById('block-one').innerHTML = values[0];
                document.getElementById('block-two').innerHTML = values[1];
            });
        })
        .catch((error) => console.log(error));
    }, []);

    return (
        <section className="about-me s3 position-relative" id="about">                
            <div className="container">
                <div className="row position-relative align-items-center">
                    <div className="col-lg-7">
                        <div id="block-one" className="mt-0"><Skeleton height={300} /></div>  
                        <div className="hire-me-s1 btn-general btn-hv-common socials-list-hd mt-4">
                            <Link to="/about" className="btn-lg btn-inner border-corner2 lt-sp08">Read more about our vision</Link>
                        </div>                               
                    </div>
                    <div className="col-lg-5 mt-3 mt-lg-0">
                        {!page?<Skeleton height={300} />:<img src={page.imageOne.fields.file.url} alt="Potency" className="img-fluid rounded" />} 
                    </div>
                </div>
                <div className="row align-items-center mt-5">
                    <div className="col-12 col-sm-5 d-none d-lg-block">
                        {!page?<Skeleton height={300} />:<img src={page.imageTwo.fields.file.url} alt="Potency" className="img-fluid rounded" />} 
                    </div>
                    <div className="col-12 col-lg-7">
                        <div id="block-two"><Skeleton height={300} /></div>
                        <p className="mt-2">* Creative memberships range from $0 - $50/month see our <Link to="/pricing">tiered plans and conditions</Link>.</p>
                        <div className="flex flex-row">
                            <div className="hire-me-s1 btn-general btn-hv-common socials-list-hd mt-4">
                                <Link to="/pricing" className="btn-lg btn-inner border-corner2 lt-sp08">Learn More</Link>
                            </div> 
                            <div className="hire-me-s1 ml-2 btn-general btn-hv-common socials-list-hd mt-4">
                                <Link to="/join" className="btn-lg btn-inner border-corner2 lt-sp08">Join the Team</Link>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    );
}

export default About;
