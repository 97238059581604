import React, { useState, useEffect } from 'react';
import { Submission, Footer, HeaderNav } from '../layouts/potency/index';
import { useAuthState } from '../../helpers/firebase';
import { Navigate } from 'react-router';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../helpers/firebase';
import { getAuth, signOut } from 'firebase/auth';

const SubmissionPage = () => {
    const { user, isAuthenticated } = useAuthState(); 
    const [state, setState] = useState({});

    useEffect(() => {
        const getDetails = async(uid) => {
            const docRef = doc(db, "users", uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                let user_data = docSnap.data()
                user_data.uid = docSnap.id;
                setState({user: user_data})
            } else {
                alert("Error: Cannot find FS user :(");
                signOut(getAuth());
            }
        }

        if(user !== null && user !== undefined) {
            getDetails(user.uid)
        }
    }, [user]);

    if(user === null && !isAuthenticated) {
        return <Navigate to="/login" />
    }
    else {
        return (
            <div className="body-copyright-light fixed counter-scroll has-one-page home3">              
                <div id="mobile-menu-overlay"><span className="tf-close" /></div>
                <HeaderNav />
                <div id="content" className="content">
                    <div className="homepage-personal">
                        <div className="tf-modal-content">
                            <section className="about-me t2 s3 shape-am position-relative" id="about">                 
                                <div className="container mt-5">
                                    {state.user === undefined?<Skeleton height={300} />:<Submission user={state.user} />}
                                </div>
                            </section>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default SubmissionPage;
