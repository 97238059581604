import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Submissions from './submissions';
import Sidebar from './account/sidebar';
import AccountIncomplete from './account/AccountIncomplete';
import { accountComplete } from '../../../helpers/utils';

const Account = (props) => {    
    const user = (props.state.user)?props.state.user:null;
    const account_complete = accountComplete(user);
    if(user) {
        user.account_complete = account_complete;
    }
    return (
        <div className="tf-modal-content">
            <section className="about-me t2 s3 shape-am position-relative" id="about">                
                <div className="container">
                    <div className="row position-relative mt-5">
                        {user?<Sidebar user={user} />:<div className="col-12 col-md-4 flat-title"><Skeleton className="mg-b41" height={38.39} /></div>}
                        <div className="col-12 col-md-8 flat-title">
                            {!account_complete && user?<AccountIncomplete />:null}
                            {user?<Submissions user={user} setSubmission={props.setSubmission} />:<Skeleton height={180} />}
                        </div>
                    </div>
                </div>
            </section>
        </div>    
    );
}

export default Account;
