import React from 'react';

const GradientSection = () => {
    return (
        <div className="gradient-bg">
            <div className="container-xl">
                <div className="row mt-5 ">
                    <div className="col-12 col-md-6">
                        <img src="https://balance.b-cdn.net/potency/Potency_Screen.png" alt="Potency Collab" />
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center px-4 px-lg-5">
                        <h3 className="text-light footerHeader">Potency Media</h3>
                        <h3 className="text-light subHeader mt-3">New Media Site starting soon.</h3>
                        <h3 className="text-light subHeader">Read, Watch and Listen to meaningful storytelling on the topics of:</h3>
                        <h3 className="text-light subHeader mt-3 mb-5 mb-lg-0">Wellness, Adventure, Culture & Innovation</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GradientSection;
