import React, { useState } from 'react';
//import { functions } from '../../../../helpers/firebase';
import { httpsCallable } from "firebase/functions";
import { functions } from '../../../../helpers/firebase';
import {LockOpenIcon} from '@heroicons/react/outline'

const Subscribed = (props) => { 
    // const [ portalURL, setPortalURL ] = useState(null);
    const [ redirecting, setRedirecting ] = useState(false);

    const portalRedirect = () => {
        setRedirecting(true);
        const portalFunction = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
        portalFunction({ returnUrl: window.location.origin+"/account" }).then((data) => {
            window.location.href = data.data.url;
        });
    }

    return (
        <>
            <span onClick={() => portalRedirect()} style={{"cursor": "pointer"}}>
                {redirecting?<><i className='fa fa-spinner fa-spin'></i> Redirecting...</>
                :<><LockOpenIcon className='hero-icon' /> Manage your Plan</>}
            </span>
        </>
    );
}

export default Subscribed;