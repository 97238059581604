import { Route, Routes } from 'react-router-dom'
import { AuthContextProvider } from './helpers/firebase'
import ScrollToTop from './helpers/ScrollToTop';
import Home from './components/pages/Home';
import JoinPage from './components/pages/JoinPage';
import LoginPage from './components/pages/LoginPage';
import AccountPage from './components/pages/AccountPage';
import SubmissionPage from './components/pages/SubmissionPage';
import VisionPage from './components/pages/VisionPage';
import PricingPage from './components/pages/PricingPage';
import SettingsPage from './components/pages/SettingsPage';

function App() {

    return (
        <AuthContextProvider>
            <ScrollToTop />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/join" element={<JoinPage />} />
                <Route exact path="/login" element={<LoginPage />} />
                <Route exact path="/account" element={<AccountPage />} />
                <Route exact path="/submission" element={<SubmissionPage />} />
                <Route exact path="/about" element={<VisionPage />} />
                <Route exact path="/pricing" element={<PricingPage />} />
                <Route exact path="/settings" element={<SettingsPage />} />
            </Routes>
        </AuthContextProvider>
    );
}

export default App;
