import React, { Component } from 'react';
import {Join, Footer, HeaderNav} from '../layouts/potency/index'
class JoinPage extends Component {
    render() {
        return (
            <div className="body-copyright-light fixed counter-scroll has-one-page home3">              
                <div id="mobile-menu-overlay"><span className="tf-close" /></div>
                <HeaderNav />
                <div id="content" className="content">
                    <div className="homepage-personal">
                        <Join />
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default JoinPage;
