import React, { Component } from 'react';
import { HeartIcon } from '@heroicons/react/24/solid';

class Footer extends Component {
    render() {
        return (
            <div className="tf-modal-content justify-content-end"> 
                <footer id="footer" className="footer footer-s2">
                    <div id="bottom" className="bottom-s3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="copyright lt-sp02">
                                        © Potency Media, 2022 All rights reserved.
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="copyright lt-sp02 developed-by">
                                        Made with{' '}<HeartIcon className="hero-icon heart-footer animate-pulse" />{' '}by <a href="https://balancesoftware.co.nz" target="_blank" rel="noreferrer">Balance Software</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
