import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore";
import { useState, useEffect, useContext, createContext } from 'react'
import { getFunctions } from "firebase/functions";

export const firebaseApp = initializeApp({ 
    apiKey: "AIzaSyA0vaUtYAC6NZPkJ4mJl4fUsdRgCNh0rE4",
    authDomain: "potency-edit.firebaseapp.com",
    projectId: "potency-edit",
    storageBucket: "potency-edit.appspot.com",
    messagingSenderId: "403677732779",
    appId: "1:403677732779:web:eb8223fd6509d1ac54fcee",
    measurementId: "G-LD6N3CRLSF"
})

export const db = getFirestore();

export const functions = getFunctions(firebaseApp, 'australia-southeast1');

export const AuthContext = createContext()

export const AuthContextProvider = props => {
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
    return () => unsubscribe()
  }, [])
  return <AuthContext.Provider value={{ user, error }} {...props} />
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.user != null }
}