import React, { useState, useRef } from 'react';
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../helpers/firebase';
import 'react-loading-skeleton/dist/skeleton.css'
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

const Upgrade = (props) => { 
    const user = props.user;

    const [ upgrading, setUpgrading ] = useState(false);
    const toastId = useRef();

    const upgradeAccount = async() => {
        toastId.current = toast.loading(
            `Redirecting...`
        );
        setUpgrading(true);
        const checkout_session = await addDoc(collection(db, "users/"+user.uid+"/checkout_sessions"), {
            price: 'price_1OCBFeJubI2Hytw0nWw2EdHY', //'price_1NKr0rJubI2Hytw0OArAheG2',
            success_url: window.location.origin+"/account",
            cancel_url: window.location.origin+"/account",
            allow_promotion_codes: true,
            billing_address_collection: 'required',
            collect_shipping_address: false,
        });

        const unsub = onSnapshot(checkout_session, (snap) => {
            const { error, url } = snap.data();
            if (error) {
                handleSnapError(error);
            }
            if (url) {
                handleSnapURL(url);
            }
        });

        const handleSnapURL = (url) => {
            setUpgrading(false);
            unsub();
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
        }

        const handleSnapError = (error) => {
            setUpgrading(false);
            unsub();
            alert(`An error occured: ${error.message}`);
        }
    }

    return (
        <>
            <p className='mt-3'>You're currently on the Tier One Plan. Consider upgrading to access <Link to="/pricing"><u>all of these features</u></Link> and more.</p>
            
            <div className="hire-me-s1 btn-general btn-hv-common socials-list-hd mt-4 mb-4">
                <button disabled={upgrading} onClick={() => upgradeAccount()} className="btn-lg btn-inner border-corner2 lt-sp08">{upgrading?"Redirecting...":"Upgrade Now"}</button>
            </div>
        </>
    );
}

export default Upgrade;